@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


*{margin: 0px; padding:0px;}

body{font-family: "Open Sans", sans-serif !important; background:#f2f3f8; overflow-x: hidden;}


header .container-fluid{
    padding-left: 0px;
    

}
header{
    width:100%;
     height:80px;
      position: fixed;
       top: 0px;
         z-index: 1000; }

header .row{margin: 0px !important;}
header .logo {text-decoration: none;}
header .logo img{
    width:170px; 
    height: 120px;
     position: relative;
      top: 8px;
    left: 8px; }
header {
    background-color: #2075b8 !important;   
}
@media(max-width: 767px){
    .part3 .buttonss{
        display: none;
    }
    
}
@media (max-width: 767px){
    .part2 Button{
        position: absolute;
        left: 350px;
        bottom: 1px;

    }
        #dropdown-basic{
            position: absolute;
            right: 25px;
            bottom: -28px;
            align-items: center;
        }
                 .distab {
                     width: 380px !important;
                 }


}
@media (max-width: 965px){
    .part2 {
            text-align: end;
        }
        .dform{
            width: 250px;
            position: relative;
            right: 200px;
            top: 20px;
        }
        .formlabel1{
            margin-left: 50px;
        }
               

}


.formlabel1{
    color: #2075b8;
    
}

/*dispute reason*/
@media only screen and (max-width: 600px){
    .ltopic {
            font-size: 15px !important;
            position: relative;
            top: 10px !important;
        }
                .rtopic Button {
                    position:relative;
                    right: 20px;
                   
                   
        
                    
                }
}
.ltopic {
    color: #2075b8;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: 6px;
    left: 10px;
}
.rtopic{

    text-align: end;
}
.rtopic Button {
 
    padding: 5px 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
    margin-right: 5px;
}


#dropdown-basic{
    color: white;
    outline: hidden !important;
    border: none;

}
.adminss .aicon1 svg{
    font-size: 18px;
    margin-bottom: 1px;
    color: #3c86f5;
   
}
.adminss .aicon2 svg {
    font-size: 18px;
    margin-bottom: 1px;
    color: #3c9103;

}

.adminss .aicon3 svg {
    font-size: 18px;
    margin-bottom: 1px;
    color: #d81414;

}

.adminss .atext{
    font-size: 14px;
    margin-left: 4px;
}
header button.button1 {
    min-width: 40px !important;
    width: 65px !important;
    height: 80px !important;
    background: #2075b8 !important;
    outline: none;
    z-index: 1;
    border-left: 2px solid whitesmoke;
    border-radius: 0px;
    
}

header button.button1 svg {
    color: white;
    font-size: 25px !important;
    transition: all 0.2s ease-in-out;
}

header button.button1:hover svg {
    color: #5491c0;

}





header button.rounded-circle{min-width: 40px !important; width: 40px !important; height: 40px !important; background:#dbe2ee !important ; outline: none; z-index: 1;}
header button.rounded-circle svg{color: #1f2a5f; font-size: 16px !important; transition: all 0.2s ease-in-out;}

header button.rounded-circle:hover svg{
    color: #0858f7;
    
}

.myaccwrapper{
    background-color: #dbe2ee;
    border-radius: 50px;
   
}
.myaccwrapper Button{
    height: 42px;
   
}



.myacc img{
    height: 30px;
}

.myacc .admin h4{
    font-size: 13px;
    font-weight: bolder;
    margin-left: 10px;
    color: rgb(83, 81, 81);
    margin-top: 8px;

}

.adminss Button{
    margin-right: 7px;
}
.adminss img{
    height: 42px;
    padding-right: 3px;
}




.main{
    overflow-x: hidden;
}
.main .sidebarWrapper{ 
    width: 14.5%;
    flex: 0 0 14.5%;
    transition: all 0.3s ease-in-out;
}
.main .sidebarWrapper.toggle{
    width: 10%;
        flex: 0 0 0%;

}
.main .sidebarWrapper .sidebar{
    width: 262.5px;
    height: 100vh;
    max-height: calc(100% - 70px);
    position: fixed;
    top: 81px;
    left: 0px;
    background: #2075b8;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 40px;
    transition: all 0.3s ease-in-out;
    padding-top: 3px;
    
}
@media only screen and (max-width: 600px) {
    .main .sidebarWrapper .sidebar{
        width: 100%;
        z-index: 1001;
    }
}
.main .sidebarWrapper.toggle .sidebar{
    left: -100%;
    opacity: 0;
}

.main .sidebarWrapper .sidebar::-webkit-scrollbar{
    width: 3px;
    height: 10px !important;
   
}
.main .sidebarWrapper .sidebar::-webkit-scrollbar-thumb{
    background: transparent !important;
}

.main .sidebarWrapper:hover .sidebar::-webkit-scrollbar{
    background: #34aeff !important;
}




.main .sidebarWrapper .sidebar ul{
    margin-bottom: 0px;
    padding: 12px 12px;
}


.main .sidebarWrapper .sidebar ul li{
    list-style: none;
    margin-top: 1px;
}
.main .sidebarWrapper .sidebar ul li button{
    color: white !important;
    text-align: left;
    justify-content: left;
    border-radius: 10px !important;
    padding: 10px 10px !important;
    align-items: center;
    text-transform: capitalize !important;
    font-weight:600;
    font-family: "Open Sans", sans-serif !important;
    outline: none;
    font-size: 13px;
}


.main .sidebarWrapper .sidebar ul li button:hover{
    background: #f1f1f1 !important;
    color: black !important;
}
.main .sidebarWrapper .sidebar ul li button {color: #fff;}
.main .sidebarWrapper .sidebar ul li button .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-right: 10px;
} 


.main .sidebarWrapper .sidebar ul li button .icon svg{
    font-size: 18px;
}


.main .sidebarWrapper .sidebar ul li button:hover{
    background: #f1f1f1 !important;
    transition: all 0.3s ease-in-out;
 
}
.main .sidebarWrapper .sidebar ul li button.active{
    background: #f1f1f1 !important;
}
.activeLi{
    background-color: #5f9ac7;
    border-radius: 10px;
}

.activeLi span{
    color: #fff;
}
.activeLii {
    background-color: #56afd2;
    border-radius: 10px;
}

.activeLii span {
    color: #fff;
}
.activeLiii {
    background-color: #56afd2;
    border-radius: 10px;
}

.activeLiii span {
    color: #fff;
}
.activeLiiii {
    background-color: #56afd2;
    border-radius: 10px;
}

.activeLiiii span {
    color: #fff;
}
.activeLLi{
    background-color: #878787;
        border-radius: 10px;
}

.main .sidebarWrapper .sidebar ul li button.active{
  color: #fd381d !important;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapsed .submenu button.active {
    background: #f1f1f1 !important;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapsed .submenu button.active {
    color: #fd381d !important;
}

.main .sidebarWrapper .sidebar ul li button .arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    transition: all 0.3s ease-in-out;
}


.main .sidebarWrapper .sidebar ul h1{
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    color: #ffffff;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 10px;
  
    
}
.main .sidebarWrapper .sidebar ul li button.active .arrow{
    transform: rotate(90deg);
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapsed .submenu button.active .arrow {
    transform: rotate(90deg);
}


.main .sidebarWrapper .sidebar ul .submenu{
    padding: 10px 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
   
   
}

.main .sidebarWrapper .sidebar ul .submenu li{
width: 110%;
list-style: none;
}
.main .sidebarWrapper .sidebar ul .submenu li a{
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    display: block;
    padding: 10px 8px;
   
}



.main .sidebarWrapper .sidebar ul .submenu li a:hover{
    background-color: #fff;
    color: black;
    background-size:auto;
    border-radius: 10px;
    padding: 10px 10px;
}

.main .sidebarWrapper .sidebar ul .submenu li .icon{
    margin-right: 8px;
    align-items: center;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper{
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.main .sidebarWrapper .sidebar ul .submenuWrapper .colapse{
    height: 0px;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapse .submenu{
    opacity: 0;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapsed {
    height: auto;
    overflow: inherit;
}

.main .sidebarWrapper .sidebar ul .submenuWrapper .colapsed .submenu {
    opacity: 1;
}


.main .content{
    width: 80%;
    flex: 0 0 80%;
    transition: all 0.3s ease-in-out;
}
.main .content.toggle{
    width: 95%;
    flex: 0 0 95%;
    transition: all 0.3s ease-in-out;

}

.right-content{
    padding: 100px 60px;
    
}
.right-content .hname h1{
    font-size: 13px;
    color: #fd381d;
   
    
}
.right-content .hname h2 {
    font-size: 17px;
    color: #2075b8;
}

@media only screen and (max-width: 600px) {
    .right-content .hname{
        position: relative;
        right: 40px;
    }
.dashboardBoxWrapper {
    display: block !important;
    margin-top: 15px !important;
    
}
.dashboardBoxWrapper .dashboardBox {
    position: relative;
    right: 20px;
    top: 30px;
    width: 130% !important;
    height: 130px !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    margin-top: 15px;
}
}

.dashboardBoxWrapper{
    gap: 25px;
    width: 108%;
    margin-top: 50px;
}
.dashboardBoxWrapper .dashboardBox{
    width: 55%;
    height: 120px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
}


.dashboardBoxWrapper .dashboardBox .cname h1{
    font-size: 14px;
    position: relative;
    top: 32px;
    left: 50px;
    color: #818ea3;
}
.dashboardBoxWrapper .dashboardBox .cname h2{
    font-size: 18px;
        position: relative;
        top: 38px;
        left: 80px;
        color: #3f51b5;
        font-weight: 600;

}


.dashboardBoxWrapper .dashboardBox .card-icon .icon {
    background-color: #fb9009;
    padding: 30px 6px;
    border-radius: 10px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon {
    position: relative;
    left: 20px;
    top: 30px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon svg {
    font-size: 60px;
    color: #fff;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon1 {
    background-color: #6420AA;
    padding: 30px 6px;
    border-radius: 10px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon1 {
    position: relative;
    left: 20px;
    top: 30px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon1 svg {
    font-size: 60px;
    color: #fff;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon2 {
    background-color: #FF71CD;
    padding: 30px 6px;
    border-radius: 10px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon2 {
    position: relative;
    left: 20px;
    top: 30px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon2 svg {
    font-size: 60px;
    color: #fff;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon3 {
    background-color: #5AB2FF;
    padding: 30px 6px;
    border-radius: 10px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon3 {
    position: relative;
    left: 20px;
    top: 30px;
}

.dashboardBoxWrapper .dashboardBox .card-icon .icon3 svg {
    font-size: 60px;
    color: #fff;
}
.dashboardBoxWrappers{
    overflow-y: hidden;
    overflow-x: hidden;
}
@media only screen and (max-width: 600px){
    .dashboardBoxWrappers{
        position: relative;
        right: 40px;
        display: block !important;
        width: 250% !important;
    }
}

.dashboardBoxWrappers {
    gap: 25px;
    width: 108%;
    margin-top: 35px;
    
}

.dashboardBoxWrappers .dashboardBoxx {
    
    width: 64%;
    height: 280px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
}

.dashboardBoxWrappers .dashboardBoxx .xname h1{
    font-size: 20px;
    margin-top: 8px;
    margin-left: 8px;
    color: #2075b8;
}
@media only screen and (max-width: 600px){
   
    .recharts-surface{
        position: relative;
        right: 80px;
        width: 330px !important;
    }
    .recharts-legend-item-text{
        position: relative;
        right: 80px;
    }
    .xname{
        position: relative;
        top: 10px;
    }
        .dashboardBoxWrappers .dashboardBoxx{
            position: relative;
                top: 10px;
        }
        
}
.dashboardBoxWrappers .dashboardBoxx .pchart{
    position: relative;
    bottom: 100px;
    left: 90px;
}

.dashboardBoxWrappers .dashboardBoxx .bchart {
    position: relative;
    left: 87px;
    top: 20px;
}

.footer{
    background-color: white;
    text-align: center;
    padding: 10px;
    width: 107%;
    
}

.footer p{
    font-size: 14px;
    color: #818ea3;
    margin-top: auto;
    margin-bottom: auto;
}


.right-content .dispatcher  h1 {
    font-size: 14px;
    position: relative;
    
    color: #2075b8;
}

.right-content .dispatcher .TabList{
    align-items: center;
   
}
@media only screen and (max-width: 600px){
    .distab .tabcontent1 .row{
        display: block !important;
    }
        .distab .tabcontent1 {
            width: 300px;
            position: relative;
            right: 45px;
        }
        .distab .tabcontent1 .row .col-4 {
            width: 300px;
        }
                .distab .tabcontent1 .row .col-8 {
                    margin-top: 5px;
                    width: 300px;
                }


                                 .tabcontent2 .row {
                                     display: block !important;
                                 }
                
                                 .tabcontent2 {
                                     width: 300px;
                                     position: relative;
                                     right: 45px;
                                 }
                
                                 .tabcontent2 .row .col-4 {
                                     width: 300px;
                                 }
                
                                 .tabcontent2 .row .col-8 {
                                     margin-top: 5px;
                                     width: 300px;
                                 }

                                .tabcontent3 .row {
                                  display: block !important;
                                 }
                                                                  .tabcontent3 {
                                                                      width: 300px;
                                                                      position: relative;
                                                                      right: 45px;
                                                                  }
                                
                                                                 
                                
                                                                  .tabcontent3 .row .col-4 {
                                                                      width: 300px;
                                                                  }
                                                                                                                                  .tabcontent3 .row .col-4 .button2 {
                                                                                                                                     position: relative;
                                                                                                                                     bottom: 38px;
                                                                                                                                     left: 170px;
                                                                                                                                  }
                                
                                                                  .tabcontent3 .row .col-8 {
                                                                      margin-top: 5px;
                                                                      width: 300px;
                                                                  }
}
.tabcontent1{
    background: #fff;
   
    
}

.tabcontent1 .ttitle h2{
    font-size: 16px;
    background-color: #eee;
    padding: 10px 15px;
    margin-top: 10px;
    color: #2075b8;
    font-weight: 400;
}

.tabcontent1 .bbody{
    height: 500px;
    overflow: auto;
    background-color: #f5f6f8;
    margin-bottom: 10px;
}
.tabcontent1 .bbody h3{
    font-size: 14px;
    padding: 8px 15px;
    color: #2075b8;
}


.tabcontent2 {
    background: #fff;


}

.tabcontent2 .ttitle h2 {
    font-size: 16px;
    background-color: #eee;
    padding: 10px 15px;
    margin-top: 10px;
    color: #2075b8;
    font-weight: 400;
}

.tabcontent2 .bbody {
    height: 500px;
    overflow: auto;
    background-color: #f5f6f8;
    margin-bottom: 10px;
}

.tabcontent2 .bbody h3 {
    font-size: 14px;
    padding: 8px 15px;
    color: #34495e;
}

.tabcontent3 {
    background: #fff;


}

.tabcontent3 .ttitle h2 {
    font-size: 16px;
    background-color: #eee;
    padding: 10px 15px;
    margin-top: 10px;
    color: #3f51b5;
    font-weight: 400;
}

.tabcontent3 .bbody {
    height: 500px;
    overflow: auto;
    background-color: #f5f6f8;
    margin-bottom: 10px;
}

.tabcontent3 .bbody h3 {
    font-size: 14px;
    padding: 8px 15px;
    color: #34495e;
}

.tabcontent3 .bbody .Form.Group{
    padding: auto;
}
.tabcontent3 .bbody .form-label{
    font-size: 12px;
}

.tabcontent3 .bbody .form-control {
    width: 90%;
    height: fit-content;
    line-height: 1.5rem;
}

.tabcontent3 .bbody .buttons {
    margin-top: 20px;
    overflow-x: hidden;
    margin-bottom: 10px;
}
.tabcontent3 .bbody .button1 {
    position: relative;
    left: 15px;
}

.tabcontent3 .bbody .button2 {
    position: relative;
    left:25px;
}
.tabc .kerus{
    align-items: start;
}
.tabc .kerus Button{
    margin-right: 5px;
    border-radius: 3px;
    background-color: #358ed7;
    color: #fff;
}

.tabc .perus{
    margin-top: 20px;
    background-color: #fff;
    width: auto;
    height: 500px;
}
@media only screen and (max-width: 600px){
    .tabc{
        position: relative;
        right: 80px;
    }
    .dispatchers .tabcontent1 .row{
        display: block;
        width: 350px;
    }
        .dispatchers .tabcontent1 .row .col-4{
            width: 350px;
            position: relative;
            right: 60px;
        }
                .dispatchers .tabcontent1 .row .col-8 {
                    width: 350px;
                    position: relative;
                    right: 60px;
                }
                .site{
                    width: 160%;
                    position: relative;
                    right: 50px;
                    
                }
}

@media only screen and (max-width: 600px){
    .right-content .contentss {
           
            width: 160% !important;
            height: 600px;
            position: relative;
            right: 50px;
            
            
        }
                .right-content .contentss .user2 .kkkk h1{
                    display: none;
                }
                                 .right-content .contentss .user2 .kkkk .searchh {
                                     display: none;
                                 }
                                                                  .right-content .contentss .user2 .kkk{
                                                                    width: 100%;
                                                                  }
                                                                 .right-content .contentss .user2 .kkk Button{
                                                                    font-size: 15px;
                                                                   
                                                                 }
                                                                                                                                 .right-content .contentss .container{
                                                                                                                                    width: 320px !important;
                                                                                                                                    position: relative;
                                                                                                                                    right: 20px;
                                                                                                                                    
                                                                                                                                 }

                                                                                                                                                                                                                                                                 .right-content .contentss .btopic{
                                                                                                                                                                                                                                                                    margin-top: 80px;
                                                                                                                                                                                                                                                                 }

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 .right-content .contentss .btopic .btopic1{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    display: none;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 }

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 .right-content .contentss .btopic .col-6{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: relative;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    right: 180px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 }
                                                                                                                                

}
.right-content .contentss{
    background-color: #fff;
    width: 105%;
    height: 900px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.right-content .contentss .user1{
    border-bottom: 1px solid rgb(67, 60, 60);
}

.right-content .contentss  .kkk{
    font-size: 24px;
    padding-left: 28px;
    color: #2075b8;
    font-weight: 500;
    margin-top: 6px;
    margin-bottom: 6px;
    
    
}
.right-content .contentss  .kkkk {
    margin-top: 5px;
    margin-bottom: 6px;
    text-align: end;
    padding-right: 29px;


}


.right-content .contentss .user2 Button .btnsss{
    text-decoration: none;
    list-style: none;
    color: white;
}

.right-content .contentss .user2 .kkkk .searchh{
    width: 200px;
}

.right-content .contentss .user2 .kkkk {
    align-items: center;
    justify-content: end;


}
.right-content .contentss .user2 .kkkk h1 {
    text-align: center;
    align-items: center;
    font-size: 16px;
    margin-top: 6px;
    font-weight: 400;
    padding-right: 2px;


}

.hhhhh{
    overflow-y: scroll;
}


.btopic1 {
    font-size: 14px;
    padding-left: 20px;
    margin-top: 5px;

}

/* This style will ensure the loader is centered on the viewport */
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Using the full height of the viewport */
    width: 100vw;
    /* Using the full width of the viewport */
    position: fixed;
    /* Make sure loader stays in place even if underlying content scrolls */
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    /* Light white background */
    z-index: 1000;
    /* High z-index to stay on top of other content */
}

/* Styling for the rotating loader */
.loader {
    border: 6px solid #f3f3f3;
    /* Light grey border for the undeterminate part */
    border-radius: 50%;
    /* Makes the div circular */
    border-top: 6px solid #3498db;
    /* Blue border for the determinate part */
    width: 50px;
    /* Diameter of the loader */
    height: 50px;
    /* Height of the loader */
    animation: spin 2s linear infinite;
    /* Animation for continuous spin */
}

/* Keyframes to create the spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
        /* Start position */
    }

    100% {
        transform: rotate(360deg);
        /* End position, full rotation */
    }
}


/*transprt*/

@media only screen and (max-width: 600px){
    .ttransport .row{
        display: block;
        width: 125%;
    }
        .ttransport .row .csub {
            width: 130%;
            margin-top: 10px;
        }
        .ttransport .dashboardBox{
            margin-left: 0px !important;
            width: 350px !important;
            position: relative;
            left: 5px;
        }

}

.transport-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
   
}

.transport {
    display: flex;
    white-space: nowrap;
   
}

.transport-container::-webkit-scrollbar {
    display: none;
   
}

.transport-container {
    -ms-overflow-style: none;
  
    scrollbar-width: none;
}
.btansport{
    margin-left: 10px;
    margin-top: 10px;
    color: white;
    border: 1px solid rgb(114, 140, 245);
    padding: 4px 6px;
    border-radius: 8px;
    font-size: 15px;

    
}
.btansport:hover {
    color: #ffffff;
    background-color: thistle;


}
.ttransport .csub h1{
    font-size: 14px;
    font-weight: 500;
    margin-left: 18px;   
}
.ttransport .csub form {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-left: 18px;
    width:max-content;
    border-radius: 10px;
}
.csub .form-select{
    width: 70%;
    font-size: 14px;
    height: 30px;
    margin-left: 18px;
}
.tdown{
    text-align: center;
}
.tdown Button{
    margin: 3px;
}

.ttransport .dashboardBox{
    width: 330px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    
}

.general{
    height: 550px;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
}
.geeneral {
    height: 700px;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.geeeneral {
    height: 800px;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.dispatchers h1{
    font-size: 14px;
    color: #1f2a5f;

}


/*Profile*/

.right-content .contentsss {
    background-color: #fff;
    width: 105%;
   
    height: 900px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
@media only screen and (max-width: 600px){
    .right-content .contentsss {
            width: 150%;
            height: 900px;
            position: relative;
            right: 30px;
        }
}
.contentsss h1{
    color: #0d3a94;
    font-size: 20px;
    position: relative;
    top: 10px;
}

.Filter{
    width: 200px;
    margin: 3px;
    font-size: 14px;
    margin-left: 350px;
}

.pagination{
    display: block;
}

.pagination .mover{
    text-align: center;
}